<template>
    <div class="wrap-off-deposit">
        <div class="nav-tab">
            <span class="tab" :class="{ activeTab: typePage == 'deposit' }" @click="filterTypeDeposit('deposit')">List Deposit</span>
            <span class="tab" :class="{ activeTab: typePage == 'metal' }" @click="filterTypeDeposit('metal')">List Metal</span>
        </div>
        <list-deposit-off
            v-if="typePage == 'deposit'"
            :depositDatas="getDataDeposit"
            :totalDeposit="getTotalData"
            :pageNumber="dataParams.currentPage"
            :totalPerPage="dataParams.perPage"
            :isLoadingData="isLoading"
            @setDataFilter="onFilterData"
            @changeRow="onChangeRow"
        ></list-deposit-off>
        <list-metal-off
            v-else
            :depositDatas="getDataDeposit"
            :totalDeposit="getTotalData"
            :pageNumber="dataParams.currentPage"
            :totalPerPage="dataParams.perPage"
            :isLoadingData="isLoading"
        ></list-metal-off>
        <div class="d-flex justify-content-end mt3" v-if="!isLoading">
            <b-pagination
                v-model="dataParams.currentPage"
                :total-rows="getTotalData"
                :per-page="dataParams.perPage"
                @change="handlePageChange"
                aria-controls="new-table"
                first-number
                last-number
                v-show="getTotalData > dataParams.perPage"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import ListDepositOff from "../../../../components/deposit/ListDepositOff.vue";
import ListMetalOff from "../../../../components/deposit/ListMetalOff.vue";
export default {
    components: { ListDepositOff, ListMetalOff },
    data() {
        return {
            dataParams: {
                activity: "deposit",
                perPage: 10,
                currentPage: 1,
                startDate: "",
                endDate: "",
                typeExchange: "offexchange",
                owner: "",
                serialNumber: "",
                hallmark: "",
                dateType: "",
                status: "",
                contract_id: "",
            },
            today: "",
            typePage: "deposit",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.getData(this.dataParams);
    },
    computed: {
        getDataDeposit() {
            return this.$store.state.bullionModule.dataShow;
        },
        getTotalData() {
            return this.$store.state.bullionModule.lengthData;
        },
        isLoading() {
            return this.$store.state.bullionModule.isloading;
        },
        getDataContract() {
            return this.$store.state.bullionModule.contractData;
        },
        getDataClient() {
            return this.$store.state.bullionModule.clientData;
        },
    },
    methods: {
        filterTypeDeposit(event) {
            this.typePage = event;
            this.getData(this.dataParams);
        },
        handlePageChange(event) {
            this.dataParams.currentPage = event;
            this.getData(this.dataParams);
        },
        onFilterData(value) {
            this.dataParams.status = value.statusMetal;
            this.dataParams.endDate = value.endDate;
            this.dataParams.startDate = value.startDate;
            this.getData(this.dataParams);
        },
        onChangeRow(value) {
            this.dataParams.perPage = value;
            this.getData(this.dataParams);
        },
        getData(valueParams) {
            if (this.typePage == "deposit") {
                this.$store.dispatch("getDepositList", {
                    serialNumber: valueParams.serialNumber,
                    perPage: valueParams.perPage,
                    currentPage: valueParams.currentPage,
                    owner: valueParams.owner,
                    startDate: valueParams.startDate,
                    endDate: valueParams.endDate,
                    status: valueParams.status,
                    type: valueParams.typeExchange,
                });
            } else {
                this.$store.dispatch("getListMetal", {
                    activity: valueParams.activity,
                    perPage: valueParams.perPage,
                    currentPage: valueParams.currentPage,
                    startDate: valueParams.startDate,
                    endDate: valueParams.endDate,
                    type: valueParams.typeExchange,
                    owner: valueParams.owner,
                    serialNumber: valueParams.serialNumber,
                    hallmark: valueParams.hallmark,
                    dateType: valueParams.dateType,
                    status: valueParams.status,
                    contract_id: valueParams.contract_id,
                });
            }
        },
    },
};
</script>

<style scoped>
.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
    justify-self: flex-start;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}
</style>
